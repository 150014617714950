import React, {ChangeEvent, KeyboardEvent, useState} from 'react';
import './App.css';

type InputRowProps = {
    spaceEvenly: boolean;
    isFirstRow: boolean;
};

const InputRow = ({ spaceEvenly, isFirstRow }: InputRowProps) => {
    const [input, setInput] = useState<string[]>([]);
    const output = input.map((character, index) => <span className="character" key={index}>{character}</span>);
    const handleInput = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
            setInput((prev) => prev.length >= 1 ? prev.slice(0, prev.length - 1) : prev);
        } else if (e.key.match(/^[-_ a-zA-Z0-9]$/) && !e.ctrlKey && !e.altKey) {
            setInput((prev) => [...prev, e.key])
        }
        e.preventDefault();
    };

    const spacingClassName = spaceEvenly ? ' spaceEvenly' : ''

    return (
        <div className={`row${spacingClassName}`}>
            <div className="inputRow">
                {output}
                <div className="middleLine" />
            </div>
            <input onKeyDown={handleInput} autoFocus={isFirstRow} size={1} />
        </div>
    );
};

function App() {
    const [spaceEvenly, setSpaceEvenly] = useState<boolean>(true);
    const [pageTitle, setPageTitle] = useState<string>('Tracing');
    const [rows, setRows] = useState<number[]>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

    const changeRows = (add: boolean) => {
        if (add) {
            setRows((prev) => [...prev, prev.length + 1]);
        } else if (rows.length > 1) {
            setRows((prev) => prev.slice(0, prev.length - 1));
        }
    };

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        setPageTitle(newTitle);

        if (newTitle.length > 0) {
            document.title = newTitle;
        }
    };

  return (
    <div className="App">
      <header className="App-header">
        Worksheet Tracing Builder
      </header>
      <main>
          <div className="noPrint settings">
              <div>
                  <span>Page Title: </span>
                  <input value={pageTitle} onChange={handleTitleChange} />
              </div>
              <div>
                  <input type="checkbox" id="spaceEvenly" checked={spaceEvenly} onChange={() => setSpaceEvenly((prev) => !prev)} />
                  <label htmlFor="spaceEvenly">Space Evenly</label>
              </div>
              <div>
                  <span>Rows {rows.length}
                      <button onClick={() => changeRows(true)}>+</button>
                      <button onClick={() => changeRows(false)}>-</button>
                  </span>
              </div>
              <div>
                  <button onClick={() => window.print()}>Print</button>
              </div>
          </div>
          <div className="pageTitle">
              {pageTitle}
          </div>
          {rows.map((rowNumber) => (
              <InputRow spaceEvenly={spaceEvenly} key={rowNumber} isFirstRow={rowNumber === 1} />
          ))}
      </main>
    </div>
  );
}

export default App;
